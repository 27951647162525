<template>
  <div class="col">
    <div v-if="hasPermission('access_tasks')">
      <!-- //? start part one ------- hideScoreAndInstruction is for importing this component in edit profile dialog at users page  -->
      <div
        v-if="!hideScoreAndInstructions"
        class="my-4"
        style="margin-left: -15px; width: calc(100% + 30px)"
      >
        <!--//?  Start Score Cards  -->
        <!-- //todo:  Start Medium & Small Screens  -->
        <div class="my-4 d-lg-none">
          <div class="col-sm-12 d-flex justify-content-center flex-column">
            <!-- //? Sequence  -->
            <div class="d-flex align-items-center">
              <p class="label sequence">
                {{ userScore.sequence != null ? userScore.sequence : 0 }}
              </p>
              <span style="font-size: 1.3rem; text-transform: capitalize">{{
                localization("Sequence")
              }}</span>
            </div>
            <!-- //? precision  -->
            <div class="d-flex align-items-center">
              <p class="label precision">
                {{ userScore.precision != null ? userScore.precision : 0 }}
              </p>
              <span style="font-size: 1.3rem; text-transform: capitalize">{{
                localization("precision")
              }}</span>
            </div>
            <!-- //? Technical Reasoning  -->
            <div class="d-flex align-items-center">
              <p class="label technical_reasoning">
                {{
                  userScore.technical_reasoning != null
                    ? userScore.technical_reasoning
                    : 0
                }}
              </p>
              <span style="font-size: 1.3rem">{{
                localization("Technical Reasoning")
              }}</span>
            </div>
            <!-- //? Confluence  -->
            <div class="d-flex align-items-center">
              <p class="label confluence">
                {{ userScore.confluence != null ? userScore.confluence : 0 }}
              </p>
              <span style="font-size: 1.3rem; text-transform: capitalize">{{
                localization("Confluence")
              }}</span>
            </div>
          </div>
        </div>
        <!-- //todo: End Large Screens  -->

        <div class="my-4 d-none d-lg-block">
          <div class="col-sm-12 px-0 px-lg-3 d-flex align-items-center">
            <!-- Sequence  -->
            <card
              :height="`${userScore.sequence * 2.5 + 120}px`"
              style="
                background-color: #39c;
                border-radius: 0;
                border: 1px solid #ccc;
                width: 100%;
                max-height: 230px;
                min-height: 120px;
              "
              class="my-auto score"
            >
              <div class="d-flex flex-column my-auto">
                <!-- score title text -->

                <!-- Medium & larger screens text   -->
                <p
                  class="mt-1 text-center text-white mb-0"
                  style="font-size: 2rem"
                >
                  {{ localization("Sequence") }}
                </p>
                <div
                  class="
                    d-flex
                    align-items-center align-items-xxl-start
                    justify-content-center justify-content-lg-around
                  "
                >
                  <!-- First Letter  -->
                  <p class="letter mb-0 text-white">
                    {{ localization("Sequence shortcut") }}
                  </p>
                  <!-- Score Number  -->
                  <!-- Small & medium Screens  -->

                  <!-- Large Screens  -->
                  <!-- Max value 35 - min value 7 = 28 & 25 is defferience between max and min font size & 50 is min font value -->
                  <p
                    class="number mb-0 text-white"
                    :style="{
                      fontSize: (userScore.sequence / 28) * 25 + 65 + 'px'
                    }"
                  >
                    {{ userScore.sequence }}
                  </p>
                </div>
              </div>
            </card>
            <!-- Precision   -->
            <card
              :height="`${userScore.precision * 2.5 + 120}px`"
              style="
                border-radius: 0;
                border: 1px solid #ccc;
                min-width: 25%;
                background-color: #3c3;
                max-height: 230px;
                min-height: 120px;
              "
              class="my-auto score"
            >
              <div class="d-flex flex-column my-auto">
                <!-- score title text -->

                <!-- Medium & larger screens text   -->
                <p
                  class="mt-1 text-center text-white mb-0"
                  style="font-size: 2rem"
                >
                  {{ localization("precision") }}
                </p>

                <div
                  class="
                    d-flex
                    align-items-center align-items-xxl-start
                    justify-content-center justify-content-lg-around
                  "
                >
                  <!-- First Letter  -->
                  <p class="letter mb-0 text-white">
                    {{ localization("precision shortcut") }}
                  </p>
                  <!-- Score Number  -->
                  <!-- Large Screens  -->
                  <!-- Max value 35 - min value 7 = 28 & 25 is defferience between max and min font size & 65 is min font value -->
                  <p
                    class="number mb-0 text-white"
                    :style="{
                      fontSize: (userScore.precision / 28) * 25 + 65 + 'px'
                    }"
                  >
                    {{ userScore.precision }}
                  </p>
                </div>
              </div>
            </card>
            <!-- Technical Reasoning  -->
            <card
              :height="`${userScore.technical_reasoning * 2.5 + 120}px`"
              style="
                border-radius: 0;
                border: 1px solid #ccc;
                min-width: 25%;
                background-color: #f93;
                max-height: 230px;
                min-height: 120px;
              "
              class="my-auto score"
            >
              <div class="d-flex flex-column my-auto">
                <!-- score title text -->
                <!-- Medium & larger screens text   -->
                <p
                  class="mt-1 text-center text-white mb-0"
                  style="font-size: 1.6rem"
                >
                  {{ localization("Technical Reasoning") }}
                </p>
                <div
                  class="
                    d-flex
                    align-items-center align-items-xxl-start
                    justify-content-center justify-content-lg-around
                    flex-column flex-md-row
                  "
                >
                  <!-- First Letter  -->
                  <p class="letter mb-0 text-white">
                    {{ localization("Technical Reasoning shortcut") }}
                  </p>
                  <!-- Score Number  -->
                  <!-- Large Screens  -->
                  <!-- Max value 35 - min value 7 = 28 & 25 is defferience between max and min font size & 65 is min font value -->
                  <p
                    class="number mb-0 text-white"
                    :style="{
                      fontSize:
                        (userScore.technical_reasoning / 28) * 25 + 65 + 'px'
                    }"
                  >
                    {{ userScore.technical_reasoning }}
                  </p>
                </div>
              </div>
            </card>
            <!-- Confluence  -->
            <card
              :height="`${userScore.confluence * 2.5 + 120}px`"
              style="
                border-radius: 0;
                border: 1px solid #ccc;
                min-width: 25%;
                background-color: #c36;
                max-height: 230px;
                min-height: 120px;
              "
              class="my-auto score"
            >
              <div class="d-flex flex-column my-auto">
                <!-- score title text -->
                <!-- Medium & larger screens text   -->
                <p
                  class="mt-1 text-center text-white mb-0"
                  style="font-size: 2rem"
                >
                  {{ localization("Confluence") }}
                </p>

                <div
                  class="
                    d-flex
                    align-items-center align-items-xxl-start
                    justify-content-center justify-content-lg-around
                  "
                >
                  <!-- First Letter  -->
                  <p class="letter mb-0 text-white">
                    {{ localization("Confluence shortcut") }}
                  </p>
                  <!-- Score Number  -->
                  <!-- Large Screens  -->
                  <!-- Max value 35 - min value 7 = 28 & 25 is defferience between max and min font size & 65 is min font value -->
                  <p
                    class="number mb-0 text-white"
                    :style="{
                      fontSize: (userScore.confluence / 28) * 25 + 65 + 'px'
                    }"
                  >
                    {{ userScore.confluence }}
                  </p>
                </div>
              </div>
            </card>
          </div>
        </div>
      </div>
      <!-- //? start part one -->

      <!-- //// start part two -->
      <div v-loading="$store.getters['user/userLoad']">
        <!-- hideScoreAndInstruction is for importing this component in edit profile dialog at users page  -->

        <card v-if="!hideScoreAndInstructions">
          <div class="new_wrapper_of_use_patterns">
            <div>
              <h3
                style="margin:  2px"
                class="personalize_title want_break_word"
                :class="{ is_rtl: isRtl() }"
                v-if="how_to_use_your_patterns"
              >
                <i class="nc-icon nc-ruler-pencil"></i>
                <span
                  class="text-capitalize"
                  v-html="how_to_use_your_patterns[0].body"
                ></span>
              </h3>
              <p class="mt-1" v-if="how_to_use_your_patterns">
                <span
                  class="want_break_word"
                  v-html="how_to_use_your_patterns[1].body"
                ></span>
              </p>
            </div>
            <div class="img_container">
              <img
                class="img"
                src="@/assets/images/Use_Your_Patterns_Graphic.jpg"
                alt=""
              />
            </div>

            <div class="which_steps_container d-none">
              <div class="steps_content_wrapper ">
                <transition name="moveUp">
                  <div
                    @click="open_add_task_dialog(1, {})"
                    class="content_numbers shadow-sm pp_one"
                    v-if="isStepNumber == 1"
                  >
                    <img
                      class="img_previous"
                      src="@/assets/images/ur-patterns/00.jpg"
                      alt="Use_Your_Patterns_Graphic"
                    />
                  </div>
                </transition>
                <transition name="moveUp">
                  <div
                    class="content_numbers shadow-sm"
                    v-if="isStepNumber == 2"
                  >
                    <img
                      class="img_previous"
                      src="@/assets/images/ur-patterns/01.jpg"
                      alt="Use_Your_Patterns_Graphic"
                    />
                  </div>
                </transition>
                <transition name="moveUp">
                  <div
                    class="content_numbers shadow-sm"
                    v-if="isStepNumber == 3"
                  >
                    <img
                      class="img_previous"
                      src="@/assets/images/ur-patterns/02.jpg"
                      alt="Use_Your_Patterns_Graphic"
                    />
                  </div>
                </transition>
                <transition name="moveUp">
                  <div
                    class="content_numbers shadow-sm"
                    v-if="isStepNumber == 4"
                  >
                    <img
                      class="img_previous"
                      src="@/assets/images/ur-patterns/03.jpg"
                      alt="Use_Your_Patterns_Graphic"
                    />
                  </div>
                </transition>
                <transition name="moveUp">
                  <div
                    class="content_numbers shadow-sm"
                    v-if="isStepNumber == 5"
                  >
                    <img
                      class="img_previous"
                      src="@/assets/images/ur-patterns/04.jpg"
                      alt="Use_Your_Patterns_Graphic"
                    />
                  </div>
                </transition>
              </div>
              <div class="steps_buttons_wrapper">
                <button
                  class="btn_number"
                  :class="{ is_active: isStepNumber == 1 }"
                  @click="switchBetweenSteps(1)"
                >
                  1
                </button>
                <button
                  class="btn_number"
                  :class="{ is_active: isStepNumber == 2 }"
                  @click="switchBetweenSteps(2)"
                >
                  2
                </button>
                <button
                  class="btn_number"
                  :class="{ is_active: isStepNumber == 3 }"
                  @click="switchBetweenSteps(3)"
                >
                  3
                </button>
                <button
                  class="btn_number"
                  :class="{ is_active: isStepNumber == 4 }"
                  @click="switchBetweenSteps(4)"
                >
                  4
                </button>
                <button
                  class="btn_number"
                  :class="{ is_active: isStepNumber == 5 }"
                  @click="switchBetweenSteps(5)"
                >
                  5
                </button>
              </div>
            </div>
          </div>
        </card>

        <!-- Tasks List  -->
        <!-- //// start Large Screens standardTopicsData -->

        <server-table
          class="hidden-sm-only hidden-xs-only"
          :count="$store.getters['user/tasks_count']"
          :DataItems="$store.getters['user/tasks']"
          @Refresh="Refresh"
          @selection="selections"
          @reset="filter = {}"
          :filter="filter"
          :title="'Tasks'"
          admin_permission="admin_users"
          edit_permission="add_tasks"
        >
          large
          <!-- search & filter fields  -->
          <template #filter>
            <!-- search by topic  -->
            <el-select
              v-model="filter.topic_id"
              clearable
              :placeholder="`${localization('Topic')}`"
              class="mt-2 select-default w-100"
            >
              <el-option
                v-for="(option, index) in $store.getters['topics/topics']"
                :key="index"
                :label="option.name"
                :value="option.id"
              ></el-option>
            </el-select>
          </template>

          <!-- Tasks Table  -->
          <template #columns>
            <!-- Select Users -->
            <el-table-column type="selection" width="50"></el-table-column>
            <!-- Task Name  -->
            <el-table-column
              prop="name"
              sortable
              :label="`${localization('Name')}`"
            ></el-table-column>
            <!-- Task Topic -->
            <el-table-column
              prop="topic_name"
              sortable
              :label="`${localization('Topic')}`"
              class="d-none d-md-block"
            ></el-table-column>

            <!-- //? Task Actions  -->
            <el-table-column prop="" label="_">
              <template slot-scope="scope">
                <div class="td_actions flex justify-content-end">
                  <!-- Edit Task Button  -->
                  <a
                    v-if="hasPermission('edit_tasks')"
                    v-tooltip.top-center="
                      `${localization('Edit personal strategy')}`
                    "
                    @click="open_task_dialog(2, scope.row)"
                    class="btn btn-warning btn-simple btn-link"
                  >
                    <i class="fa fa-edit"></i
                  ></a>
                  <!-- show task button  -->
                  <router-link
                    v-if="hasPermission('show_tasks')"
                    :to="`/edit-task/${scope.row.id}`"
                    v-tooltip.top-center="
                      `${localization('Show personal strategy')}`
                    "
                    class="btn btn-info btn-link btn-xs"
                  >
                    <i class="fa fa-eye"></i
                  ></router-link>
                  <!-- Delete Task Button  -->
                  <a
                    v-if="hasPermission('edit_tasks')"
                    v-tooltip.top-center="
                      `${localization('Delete personal strategy')}`
                    "
                    @click="remove(scope.row.id)"
                    class="btn btn-danger btn-simple btn-link"
                  >
                    <i class="fa fa-times"></i
                  ></a>
                </div>
              </template>
            </el-table-column>
          </template>
          <!-- Actions -->
          <template #action>
            <button
              class="border-0 text-dark d-block"
              style="background: transparent"
              :disabled="selected.length == 0"
            >
              <el-icon class="el-icon-plus"></el-icon>
              {{ localization("Push Task") }}
            </button>
          </template>
        </server-table>

        <!-- //// end Large Screens  -->

        <!-- //// start Small & medium Screens  -->
        <server-table
          class="hidden-md-only hidden-lg-only hidden-xl-only"
          :count="$store.getters['user/tasks_count']"
          :DataItems="$store.getters['user/tasks']"
          @Refresh="Refresh"
          @reset="filter = {}"
          :filter="filter"
          :title="'Tasks'"
          admin_permission="admin_users"
          edit_permission="add_tasks"
        >
          <!-- search & filter fields  -->
          <template #filter>
            <!-- search by topic  -->
            <el-select
              v-model="filter.topic_id"
              clearable
              :placeholder="`${localization('Topic')}`"
              class="mt-2 select-default w-100"
            >
              <el-option
                v-for="(option, index) in $store.getters['topics/topics']"
                :key="index"
                :label="option.name"
                :value="option.id"
              ></el-option>
            </el-select>
          </template>

          <!-- Tasks Table  -->
          <template #columns>
            <!-- Select Users -->
            <el-table-column type="selection" width="50"></el-table-column>
            <!-- Task Name  -->
            <el-table-column prop="name" :label="`${localization('Name')}`">
              <template slot-scope="scope">
                <div
                  class="
                  task_user_profile_wrapper
                    d-flex
                    flex-column flex-sm-row
                    justify-content-sm-between
                    align-items-sm-center
                  "
                >
                  {{ localization("Name") }}: {{ scope.row.name }}

                  <br />
                  {{ localization("Topic") }}: {{ scope.row.topic_name }}
                  <div>
                    <!-- Edit Task Button  -->
                    <a
                      v-if="hasPermission('edit_tasks')"
                      v-tooltip.top-center="
                        `${localization('Edit personal strategy')}`
                      "
                      @click="open_task_dialog(2, scope.row)"
                      class="btn btn-warning btn-simple btn-link"
                    >
                      <i class="fa fa-edit"></i
                    ></a>
                    <!-- show task button  -->
                    <router-link
                      v-if="hasPermission('show_tasks')"
                      :to="`/edit-task/${scope.row.id}`"
                      v-tooltip.top-center="
                        `${localization('Show personal strategy')}`
                      "
                      class="btn btn-info btn-link btn-xs"
                    >
                      <i class="fa fa-eye"></i
                    ></router-link>
                    <!-- Delete Task Button  -->
                    <a
                      v-if="hasPermission('edit_tasks')"
                      v-tooltip.top-center="
                        `${localization('Delete personal strategy')}`
                      "
                      @click="remove(scope.row.id)"
                      class="btn btn-danger btn-simple btn-link"
                    >
                      <i class="fa fa-times"></i
                    ></a>
                  </div>
                </div>
              </template>
            </el-table-column>
          </template>
          <!-- Actions -->
          <template #action>
            <button
              class="border-0 text-dark d-block"
              style="background: transparent"
              :disabled="selected.length == 0"
            >
              <el-icon class="el-icon-plus"></el-icon>
              {{ localization("Push Task") }}
            </button>
          </template>
        </server-table>
        <!-- //! end Small & medium Screens  -->
      </div>
      <!-- ///! end part two -->
      <!-- <div>
        <button>hello</button>

        <form action="">
          <input type="file" @change="handle_test($event)" />
          <input v-model="test_val" type="text" />
        </form>
      </div> -->
      <!-- <button @click="show_task_dialog = true">click</button> -->
      <!-- //? start add or  edit task  -->
      <el-dialog
        :title="
          this.type == 1 ? 'Add Personal strategy' : 'Edit Personal strategy'
        "
        :visible.sync="task_dialog"
        width="50%"
        top="4vh"
        append-to-body
      >
        <el-form
          :model="task"
          :rules="rules"
          ref="taskRef"
          @submit.prevent="save"
          label-position="top"
        >
          <el-form-item label="Name" prop="name">
            <el-input
              v-model="task.name"
              autocomplete="off"
              style="width: 100%"
            ></el-input>
          </el-form-item>
          <!-- //? description here  -->
          <el-form-item label="Description" prop="description">
            <div class="main_choose_photo">
              <input
                @change="handle_upload_file_img($event)"
                class="d-none"
                id="choose_photo_description"
                type="file"
              />
              <label class="label_custom_img" for="choose_photo_description"
                >Add description photo</label
              >
            </div>

            <el-input
              v-model="task.description"
              autocomplete="off"
              style="width: 100%"
            ></el-input>
          </el-form-item>
          <el-form-item label="Topic" prop="topic_id">
            <el-select
              v-model="task.topic_id"
              clearable
              placeholder=" Topic"
              style="width: 100%"
            >
              <el-option
                v-for="(item, index) in $store.getters['topics/topics']"
                :key="index"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div
          slot="footer"
          class="
            dialog-footer
          "
        >
          <el-button @click="task_dialog = false" class="text-center d-block "
            >Cancel</el-button
          >
          <el-button
            type="primary"
            @click="save"
            class="
              text-center
              d-block
              text-capitalize
            "
            >Save</el-button
          >
        </div>
      </el-dialog>
      <!-- //? end add or  edit task  -->

      <!-- // todo start main add or edit task  -->
      <!-- ${localization('Add personal strategy')} -->
      <el-dialog
        :title="
          this.type == 1
            ? `${localization('Add A Task')}`
            : `${localization('Edit A Task')}`
        "
        :visible.sync="show_task_dialog"
        width="50%"
        top="4vh"
        append-to-body
        :close-on-click-modal="false"
        :class="{ 'text-right ': isRtl() }"
      >
        <form class="add_edit_task_form" @submit.prevent="save_task">
          <!-- start name  -->
          <div class="every_form_group" :class="{ 'with_rtl ': isRtl() }">
            <label class="d-block form_label">
              <span class=" span_container">{{
                localization("Name Your Task")
              }}</span>
              <!-- <span class=" span_container">{{ localization("Name") }}</span> -->
            </label>
            <input
              type="text"
              :class="{
                error_input: !$v.name.required && $v.name.$dirty
              }"
              class="d-block form_input"
              v-model="name"
            />
            <div class="validation_error">
              <span
                v-if="!$v.name.required && $v.name.$dirty"
                class="text_validation"
              >
                Please this field is required
              </span>
            </div>
          </div>
          <!-- end name  -->
          <!-- start description  -->
          <div class="every_form_group" :class="{ 'with_rtl ': isRtl() }">
            <div class="in_task_choose_photo" :class="{ 'with_rtl ': isRtl() }">
              <label class="d-block form_label">
                <span class="span_container d-block">{{
                  localization("Type in, or copy and paste")
                }}</span>
                <span class="span_container d-block">{{
                  localization("You can also upload images")
                }}</span>
              </label>
              <div class="main_choose_photo">
                <input
                  @change="handle_upload_file_img($event)"
                  class="d-none"
                  id="choose_photo_description"
                  type="file"
                  accept="image/apng, image/jpeg, image/png, image/webp"
                />
                <label
                  :class="{ processing: processing }"
                  class="label_custom_img"
                  for="choose_photo_description"
                  ><span v-if="!processing">{{
                    localization("Extract from photo")
                  }}</span>
                  <div class="loader_ocr" v-if="processing"></div>
                </label>
              </div>
            </div>
            <textarea
              :class="{
                error_input: !$v.description.required && $v.description.$dirty
              }"
              class="d-block form_input with_textarea"
              v-model="description"
            ></textarea>

            <div class="validation_error">
              <span
                v-if="!$v.description.required && $v.description.$dirty"
                class="text_validation"
              >
                Please this field is required
              </span>
            </div>
          </div>

          <!-- start  Topic -->
          <div class="every_form_group" :class="{ 'with_rtl ': isRtl() }">
            <label class="d-block form_label">
              <span class="span_container ">{{
                localization("Select Your task's topic")
              }}</span>
              <!-- <span class="span_container">{{ localization("Select Your task's topic") }}</span> -->
            </label>
            <el-select
              v-model="topic_id"
              clearable
              :placeholder="`${localization('Topic')}`"
              style="width: 100%"
              :class="{
                error_input: !$v.topic_id.required && $v.topic_id.$dirty,
                'text-right ': isRtl()
              }"
            >
              <el-option
                v-for="(item, index) in standardTopicsData"
                :key="index"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
            <div class="validation_error">
              <span
                v-if="!$v.topic_id.required && $v.topic_id.$dirty"
                class="text_validation"
              >
                Please this field is required
              </span>
            </div>
          </div>
          <!-- end  Topic -->
          <div class="form_buttons_container">
            <button
              class="form_cancel_button form_save_button text-center d-block "
              @click="close_task_dialog"
            >
              {{ localization("Cancel") }}
            </button>
            <button
              class="
              text-center
              d-block
              text-capitalize
              form_save_button
            "
            >
              {{ localization("Save") }}
            </button>
          </div>
        </form>
      </el-dialog>
      <!-- // todo end main add or edit task  -->
    </div>

    <div class="container-fluid mb-2">
      <el-alert
        class="mb3 mt-2 text-center"
        v-if="!hasPermission('access_lci')"
        type="error"
        show-icon
        :closable="false"
        effect="dark"
      >
        <template>
          <h5 class="my-0" style="margin-bottom: 2px !important">
            {{
              localization("update your plan") != false
                ? localization("update your plan")
                : "Kindly update your plan to access this page"
            }}
            <router-link
              to="/edit-user"
              class="text-white text-uppercase renewLink"
            >
              {{ localization("Renew Subscription") }}
            </router-link>
          </h5>
        </template>
      </el-alert>
    </div>
  </div>
</template>

<script>
import { Card, Table as LTable } from "src/components/index";
import Swal from "sweetalert2";
import ServerTable from "../../components/DataTablePagination.vue";
import "element-ui/lib/theme-chalk/display.css";
import { required } from "vuelidate/lib/validators";
export default {
  name: "index",
  components: {
    Card,
    LTable,
    ServerTable
  },
  props: {
    hideScoreAndInstructions: null
  },
  data() {
    return {
      isStepNumber: 1,
      processing: false,
      name: null,
      description: null,
      topic_id: null,
      task_id: null,
      task: {
        name: null,
        description: "",
        topic_id: null
      },
      test_val: null,
      filter: {},
      task_dialog: false,
      type: null,
      description_photo: null,
      rules: {
        name: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ],
        description: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ],
        topic_id: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "change"
          }
        ]
      },
      show_task_dialog: false,
      imagePreview: null,
      reader: new FileReader(),
      steps: [
        {
          target: "#v-step-0", // We're using document.querySelector() under the hood
          content: `0000000000000000`
        },
        {
          target: ".v-step-1",
          content: "Action"
        },
        {
          target: '[data-v-step="2"]',
          content: "step 3333",
          params: {
            placement: "top"
          }
        }
      ],
      myOptions: {
        useKeyboardNavigation: true,
        labels: {
          buttonSkip: "Skip tour",
          buttonPrevious: "Previous",
          buttonNext: "Next",
          buttonStop: "Finish"
        }
      },
      isHide: false,
      selected: []
    };
  },
  computed: {
    id() {
      return this.$route.params.id ? this.$route.params.id : this.auth().id;
    },
    tasks() {
      return this.$store.getters["user/tasks"];
    },
    taskInstructions() {
      return this.$store.getters["user/taskInstructions"]
        ? this.$store.getters["user/taskInstructions"][0].body
        : "";
    },
    how_to_use_your_patterns() {
      return this.$store.getters["user/get_how_to_use_your_patterns"];
    },
    standardTopicsData() {
      return this.$store.getters["topics/getStandardTopics"];
    },
    userScore() {
      return this.$store.getters["user/score"];
    }
  },
  created() {
    this.reader.onload = () => {
      this.imagePreview = this.reader.result;
    };
  },
  mounted() {
    this.Refresh();
    // to get user score
    // this.$tours["myTour"].start();
  },
  methods: {
    handlerTourFun() {
      // this.$tours["myTour"].start();
      this.isHide = true;
    },
    // get_ocr_photo_description
    Refresh(query) {
      this.$store
        .dispatch("user/userTask", { id: this.id, query: query })
        .then(() => {
          this.$store.dispatch("topics/GetTopics", { query: null });
        });
      this.$store.dispatch("user/handlerHowToUseYourPatterns");
      this.$store.dispatch("topics/handlerStandardTopics");
    },
    open_add_task_dialog(type) {
      this.type = type;
      this.show_task_dialog = true;
    },
    open_task_dialog(type, taskData) {
      this.task = { ...taskData };
      ///// set values in task requirements
      this.name = taskData.name;
      this.description = taskData.description;
      this.topic_id = taskData.topic_id;
      this.task_id = taskData.id;
      this.type = type;
      this.show_task_dialog = true;
      // this.task_dialog = true;
    },
    close_task_dialog() {
      this.show_task_dialog = false;
      this.name = null;
      this.description = null;
      this.topic_id = null;
      this.$v.$reset();
    },
    handle_upload_file_img(e) {
      this.processing = true;

      this.description_photo = e.target.files[0];
      let payload = new FormData();
      payload.append("photo", e.target.files[0]);
      // this.reader.readAsDataURL(this.description_photo);
      this.$store
        .dispatch("user/get_ocr_photo_description", payload)
        .then(response => {
          this.description = response.photo_description;
          this.processing = false;
          Swal.fire({
            title: "",
            icon: "success",
            text: `${this.localization("Check Extracted Photo")}`,
            type: "success",
            confirmButtonText: `${this.localization("Ok")}`,
            confirmButtonClass: "btn btn-success ",
            buttonsStyling: false
          });
        });
    },
    save() {
      this.$refs["taskRef"].validate(valid => {
        // if all required fields filled with valide values
        // console.log(this.task.description.replaceAll(" ", "__"));
        if (valid) {
          this.description = this.description.replace(/\s/g, "_");
          // if add new task
          if (this.type == 1) {
            this.$store
              .dispatch("user/add_task", {
                query: {
                  name: this.name,
                  description: this.description,
                  topic_id: this.topic_id
                }
              })
              .then(_ => {
                this.show_task_dialog = false;
                Swal.fire({
                  title: "",
                  icon: "success",
                  text: `${this.localization("Done")}`,
                  confirmButtonText: `${this.localization("Ok")}`,
                  type: "success",
                  confirmButtonClass: "btn btn-success ",
                  buttonsStyling: false
                });
              })
              .catch(_ => {
                this.task_dialog = false;
              });
          } else {
            // if edit an existing task
            // console.log(this.task);
            // console.log(this.task.id);
            this.$store
              .dispatch("user/edit_task", {
                query: {
                  name: this.name,
                  description: this.description,
                  topic_id: this.topic_id
                },
                id: this.id
              })
              .then(_ => {
                this.task_dialog = false;
                Swal.fire({
                  title: "",
                  icon: "success",
                  text: `${this.localization("Done")}`,
                  confirmButtonText: `${this.localization("Ok")}`,
                  type: "success",
                  confirmButtonClass: "btn btn-success ",
                  buttonsStyling: false
                });
                this.Refresh({});
              })
              .catch(_ => {
                this.task_dialog = false;
              });
          }
        } else {
          // if not all required fields filled with valide values
          console.log("error submit!!");
          return false;
        }
      });
    },

    remove(id) {
      Swal.fire({
        title: "",
        text: `${this.localization("Are You Sure")}`,
        type: "warning",
        icon: "error",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success ",
        cancelButtonClass: "btn btn-danger ",
        confirmButtonText: `${this.localization("Delete")}`,
        cancelButtonText: `${this.localization("Cancel")}`,
        buttonsStyling: false
      }).then(date => {
        if (date.isConfirmed) {
          this.$store.dispatch("user/delete_task", id).then(_ => {
            this.Refresh(null);
            Swal.fire({
              title: "",
              icon: "success",
              type: "success",
              text: `${this.localization("Deleted")}`,
              confirmButtonText: `${this.localization("Ok")}`,
              confirmButtonClass: "btn btn-success ",
              buttonsStyling: false
            });
          });
        }
      });
    },
    save_task() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log("error");
      } else {
        if (this.type == 1) {
          this.$store
            .dispatch("user/add_task", {
              query: {
                name: this.name,
                description: this.description,
                topic_id: this.topic_id
              }
            })
            .then(_ => {
              this.show_task_dialog = false;
              this.$v.$reset();
              Swal.fire({
                title: "",
                icon: "success",
                text: `${this.localization("Done")}`,
                confirmButtonText: `${this.localization("Ok")}`,
                type: "success",
                confirmButtonClass: "btn btn-success ",
                buttonsStyling: false
              });
            })
            .catch(_ => {
              this.show_task_dialog = false;
            });
        } else {
          this.$store
            .dispatch("user/edit_task", {
              query: {
                name: this.name,
                description: this.description,
                topic_id: this.topic_id
              },
              id: this.task_id
              // /edit-task/
            })
            .then(_ => {
              this.show_task_dialog = false;
              this.$v.$reset();
              Swal.fire({
                title: "",
                icon: "success",
                text: `${this.localization("Done")}`,
                confirmButtonText: `${this.localization("Ok")}`,
                type: "success",
                confirmButtonClass: "btn btn-success ",
                buttonsStyling: false
              });

              this.Refresh({});
              this.$router.push(`/edit-task/${this.task_id}`);
            })
            .catch(_ => {
              this.show_task_dialog = false;
            });
        }
      }
    },
    switchBetweenSteps(num) {
      this.isStepNumber = num;
    },
    selections(selections) {
      this.selected = Array.from(selections, el => el.id);
      console.log(this.selected);
      // this.selectedInst.users = Array.from(selections, el => el.id);
    },
    // getUserCareerTasks
    printTasks() {
      let payload = {
        tasks: this.selected
      };
      this.$store.dispatch("user/handlePrintSchoolTasks", payload).then(() => {
        // window.print()
        // this.Refresh();
      });
    }
  },
  validations: {
    name: {
      required
    },
    description: {
      required
    },
    topic_id: {
      required
    }
    // task: {

    // }
  }
};
</script>

<style scoped lang="scss">
.moveUp-enter-active {
  animation: moveUp 0.5s ease-in;
}
@keyframes moveUp {
  0% {
    // transform: translateY(400px);
    // opacity: 0;
    transform: scale(0);
  }
  100% {
    // transform: translateY(0);
    // opacity: 1;
    transform: scale(1);
  }
}
.which_steps_container {
  .steps_content_wrapper {
    overflow: hidden;
    padding: 15px;
    .content_numbers {
      // box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2), 0 0 5px -2px rgba(0, 0, 0, 0.1);
      display: flex;
      justify-content: center;
      align-items: center;
      width: 700px;
      height: 330px;
      min-height: 330px;
      max-height: 330px;
      border-radius: 10px;
      padding: 20px;
      &.pp_one {
        cursor: pointer;
      }
      .img_previous {
        width: 100%;
        max-height: 100%;
        display: block;
      }
    }
  }
  .steps_buttons_wrapper {
    // background-color: #ccc;
    display: flex;
    gap: 10px;
    margin-top: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 700px;
    .btn_number {
      background-color: #e0e0e0;
      border: none;
      padding: 10px;
      border-radius: 50%;
      height: 30px;
      width: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      transition: 0.3s;
      &.is_active,
      &:hover {
        background-color: #24ccef;
      }
    }
  }
}
.new_wrapper_of_use_patterns {
  .img_container {
    .img {
      width: 100%;
      display: block;
    }
  }
  .personalize_title {
    display: flex;
    gap: 8px;
    &.is_rtl {
      flex-direction: row-reverse;
    }
  }
  .want_break_word {
    p {
      span {
        word-wrap: break-word;
        white-space: pre-wrap !important;
      }
    }
  }
}
// ::-webkit-scrollbar {
//   width: 5px;
// }
// ::-webkit-scrollbar-thumb {
//   background-color: $main-color;
//   border-radius: 10px;
// }
.lds-ring {
  display: inline-block;
  position: relative;
  width: 35px;
  height: 35px;
  margin-top: 5px;
  background: #11bfe3;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 35px;
  height: 35px;
  // margin: 8px;
  border: 3px solid #fff;
  border-radius: 50%;
  animation: lds-ring 0.7s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.renewLink {
  text-decoration: underline;
}
.text-align {
  text-align: center;
}

/* mobile score progress design */

::v-deep .precision {
  margin: 5px;
  display: inline;
  background-color: #3c3;
}

::v-deep .technical_reasoning {
  display: inline;
  margin: 5px;

  background-color: #f93;
}

::v-deep .sequence {
  display: inline;
  margin: 5px;

  background-color: #39c;
}

::v-deep .confluence {
  display: inline;
  margin: 5px;

  background-color: #c36;
}

.label {
  border-radius: 50%;
  margin: 0.5rem;
  width: 3rem;
  height: 3rem;
  text-align: center;
  font-size: 1rem;
  line-height: 2.8;
  color: white;
}

.letter {
  font-size: 40px;
}
.swal2-container.swal2-bottom,
.swal2-container.swal2-center,
.swal2-container.swal2-top {
  grid-template-columns: auto minmax(0, 1fr) auto;
  z-index: 100000;
}

.name {
  font-weight: 300;
  font-size: 19px;
  line-height: 1.2;
  padding-left: 0;
  color: white;
}

.border {
  border: 2px solid white !important;
}

.number {
  line-height: 1;
}

.row {
  margin-top: 0;
}

.v-expansion-panel--active > button {
  background-color: #757575 !important;
  color: #fff;
}

.v-expansion-panel--active > .theme--light .v-icon {
  color: #fff !important;
}

.child.v-expansion-panel--active > button {
  background-color: #26a1da !important;
  color: #fff;
}

.v-slider--horizontal .v-slider__track-container {
  height: 10px !important;
}

// ? dialog style
::v-deep .el-dialog {
  @media (min-width: 320px) and (max-width: 480px) {
    width: 90% !important;
  }
  @media (min-width: 480px) and (max-width: 767px) {
    width: 70% !important;
  }
}
.dialog-footer {
  display: flex;
  justify-content: end;
  align-items: center;
  @media (min-width: 320px) and (max-width: 767px) {
    justify-content: center;
  }
}

//? label and choose photo
::v-deep .el-form-item__label {
  margin-bottom: 0;
  float: left;
  &::before {
    content: "*";
    color: #f56c6c;
    margin-right: 4px;
  }
}

// wrap word in task and in user profile
.task_user_profile_wrapper {
  word-break: break-word;
  white-space: pre-wrap;
}

.add_edit_task_form {
  .every_form_group {
    margin-bottom: 32px;
    .form_label {
      color: #606266;
      font-size: 14px;
      // text-transform: capitalize;
      padding-left: 10px;
      margin-bottom: 10px;
      // width: fit-content;
      .span_container {
        position: relative;
        word-break: break-word;
        max-width: 98%;
        &::before {
          content: "*";
          color: #f56c6c;
          position: absolute;
          // left: 0;
          left: -10px;
          top: -3px;
        }
      }
    }
    .form_input {
      color: #606266;
      width: 100%;
      border: 1px solid #c0c4cc;
      outline: none;
      transition: 0.3s;
      border-radius: 4px;
      font-size: inherit;
      height: 40px;
      line-height: 40px;
      outline: 0;
      padding: 0 15px;
      &.with_textarea {
        height: 150px !important;
        line-height: 1.7 !important;
        padding: 10px 15px;
        resize: none;
        word-break: break-word;
      }
      &:focus {
        border-color: #11bfe3;
      }
      // &.error_input {
      //   border-color: #f56c6c;
      // }
    }
    .validation_error {
      color: #f56c6c;
      font-size: 12px;
      line-height: 1;
      padding-top: 4px;
      .text_validation {
      }
    }
    &.with_rtl {
      .form_input {
        text-align: right;
        &.with_textarea {
          text-align: right;
        }
      }
    }
  }
  .form_buttons_container {
    display: flex;
    justify-content: end;
    align-items: center;
    @media (min-width: 320px) and (max-width: 767px) {
      justify-content: center;
    }
    .form_save_button {
      transition: 0.3s all ease-in-out;
      background-color: #00563f;
      color: #fff;
      opacity: 0.7;
      max-width: 180px;
      padding: 8px 20px;
      font-size: 14px;
      border-radius: 4px;
      font-weight: 500;
      border: 1px solid #00563f;
      width: 180px;
      &.form_cancel_button {
        margin-right: 10px;
        border: 1px solid #fb404b;
        background-color: #fb404b;
      }
    }
  }
  .in_task_choose_photo {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .main_choose_photo {
      margin-bottom: 10px;
      .label_custom_img {
        padding: 8px 15px;
        border-radius: 5px;
        cursor: pointer;
        color: #fff;
        margin-bottom: 10px;
        transition: 0.3s all;
        background-color: #11bfe3;
        background-color: #00563f;
        min-width: 144px;
        position: relative;
        height: 37px;
        &:hover {
          opacity: 0.8;
        }
        &.processing {
          pointer-events: none;
        }
        .loader_ocr {
          position: absolute;
          width: 25px;
          height: 25px;
          margin-bottom: 7px;
          left: calc(50% - 12.5px);
          top: 15%;
          border: 3px solid #fff;
          border-radius: 50%;
          animation: rotation 0.7s linear infinite;
          border-color: #fff #fff #fff transparent;
        }
      }
    }
    &.with_rtl {
      flex-direction: row-reverse;
    }
  }
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>

<style lang="scss">
.main_youtube_html_render_for_tasks {
  .row {
    flex-direction: row-reverse;
    ul {
      text-align: right;
      list-style: none;
      margin-left: 0 !important;
      padding-left: 0 !important;
      li {
        text-align: right;
        position: relative;
        padding-right: 17px;
        text-align: right;
        &::before {
          position: absolute;
          content: "";
          background-color: #706f6f;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          right: 0;
          top: 7px;
        }
      }
    }
  }
}

.new_wrapper_of_use_patterns {
  .want_break_word {
    p {
      span {
        word-wrap: break-word;
        white-space: pre-wrap !important;
      }
    }
  }
}
</style>
